<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="card-title">
        <div class="row">
          <div class="col-6 text-left">
            <h4></h4>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-primary" @click="addSampleQuestion" v-if="checkIsAccessible('sample-question', 'create')">
              <span>

              </span>
              Add Sample Question
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table">
            <thead>
              <th>Title</th>
              <th>Status</th>
              <th>Action</th>
            </thead>
            <tbody v-if="samples.length>0">
              <tr v-for="(sample, index) of samples" :key="index">
                <td>{{sample.title}}</td>
                <td>
                  <i v-if="sample.is_active" class="fas fa-check text-success"></i>
                  <i v-else class="fas fa-ban text-danger"></i>
                </td>
                <td>
                  <span class="cursor-pointer" v-if="checkIsAccessible('sample-question', 'edit')" @click="editSampleQuestion(sample)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="cursor-pointer ml-2" v-if="checkIsAccessible('sample-question', 'delete')" @click="deleteSampleQuestions(sample.id)">
                    <i class="fas fa-trash"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td></td>
                <td></td>
                <td>No Data Found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <create-and-update
      v-if="program_obj.id"
      ref="sample-question"
      :program_id="program_obj.id"
      @refresh_list="getSampleDates"
    ></create-and-update>
  </div>
</template>
<script>
import SampleQuestionService from "@/core/services/level/program/sample-question/SampleQuestionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CreateAndUpdate from "./CreateAndUpdate";

const programService = new ProgramService();
const sampleQuestionService = new SampleQuestionService();
export default {
  props:['program'],
  components: {
    CreateAndUpdate
  },

  data() {
    return {
      samples: [],
      program_obj: {}
    };
  },
  computed: {

  },
  mounted() {
    this.getSampleDates();
    this.getProgram();
  },
  methods: {
    getSampleDates() {
      sampleQuestionService.getByProgram(this.program).then(response => {
        this.samples = response.data;
      });
    },
    getProgram() {
      programService.getByAbstract(this.program).then(response => {
        this.program_obj = response.data.program;
      });
    },
    addSampleQuestion() {
      this.$refs["sample-question"].showModal();
    },
    editSampleQuestion(program) {
      this.$refs["sample-question"].showModal(program);
    },
    deleteSampleQuestions(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            sampleQuestionService.delete(id).then(response => {
              this.getSampleDates();
            });
          }
        }
      });
    }
  }
};
</script>
