<template>
  <v-app>
    <b-modal
      id="modal-scrollable"
      size="md"
      ref="my-modal"
      hide-footer
      title="Sample Questions File Upload"
    >
      <div class="row">
        <div class="col-9">
          <v-file-input outlined dense v-model="sample.path" label="File" accept=".pdf"></v-file-input>
          <span class="text-danger" v-if="!sample.id  && $v.sample.path.$error">File is required</span>
        </div>
        <div class="col-3 mt-4">
          <b-form-checkbox v-model="sample.is_active" name="check-button" class switch>Status</b-form-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-right">
          <v-btn
              color="red"
              class="btn btn-standard"
              depressed
              @click="hideModal"
          >Cancel
          </v-btn>
          <v-btn  v-if="checkIsAccessible('sample-question', 'create')"
              class="text-white ml-2 btn btn-primary"
              depressed
              :disabled="sizeExceed"
              @click="saveOrUpdate"
              :loading="isBusy"
          >Save
          </v-btn>
        </div>
      </div>
      <!-- <pre>{{date}}</pre> -->
    </b-modal>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import SampleQuestionService from "@/core/services/level/program/sample-question/SampleQuestionService";
const sampleQuestionService = new SampleQuestionService();

export default {
  validations() {
    return {
      sample: this.rules
    };
  },
  props: ["program_id"],
  data() {
    return {
      edit: false,
      file: null,
      sample: {
        path: null,
        is_active: true
      }
    };
  },
  computed: {
    rules() {
      let rule = {
        is_active:{ required }
      };
      if (!this.sample.id) {
        rule.path = { required };
      }
      return rule;
    }
  },
  methods: {
    showModal(sample = {}) {
      if (sample.id) {
        this.edit = true;
        this.sample = sample;
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    saveOrUpdate() {
      this.sample.program_id = this.program_id;
      this.$v.sample.$touch();
      if (this.$v.sample.$error) {
        setTimeout(() => {
          this.$v.sample.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateEntranceDate(fd);
        } else {
          this.saveEntranceDate(fd);
        }
      }
    },
    updateEntranceDate(fd) {
      sampleQuestionService.update(this.sample.id, fd).then(response => {
        this.$snotify.success("Information updated");
        this.resetForm();
      });
    },
    saveEntranceDate(fd) {
      sampleQuestionService.store(fd).then(response => {
        this.$snotify.success("Information added");
        this.resetForm();
      });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.sample) {
        if (
          key == "path" &&
          this.sample["path"] &&
          this.sample["path"] != null
        ) {
          fd.append("path", this.sample[key]);
          fd.append("title", this.sample[key].name);
        } else if (key == "is_active") {
          fd.append("is_active", this.sample["is_active"] ? 1 : 0);
        } else {
          fd.append(key, this.sample[key]);
        }
      }
      return fd;
    },
    resetForm() {
      this.date = {
        path: null,
        is_active: true,
        program_id: this.program_id
      };
      this.$v.sample.$reset();
      this.hideModal();
      this.$emit("refresh_list");
    }
  }
};
</script>

        <style>
</style>

